import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import InnerPage, { Section } from '../components/InnerPage'
import PageHeader from '../components/PageHeader'

function Ueber() {
    return (
        <InnerPage title="Über uns">
            <PageHeader title="Über uns" />
            <Section>
                <div className="container">
                    <div className="row gutters-50">
                        <div className="col-lg-7">
                            <p>Gesellschaftlicher Zusammenhalt wird nicht von einer Person, einer Gruppe oder einer bestimmten Bevölkerung erlangt werden. Es bedarf der Beteiligung aller Menschen zum Wohlergehen der Allgemeinheit beizutragen. Geeint in diesem Vorhaben - gesellschaftlichen Zusammenhalt in einer pluralen Gesellschaft zu fördern - arbeiten Bahá’i mit einer ständig wachsenden Anzahl an Organisationen und Einzelpersonen zusammen. Hierbei lernt man voneinander, kann Erfahrungen austauschen und gemeinsames Verständnis und Handlungslinien entwickeln. Als eine solche Plattform versteht sich auch dieser Blog.</p>
                            <p>Wie sich Veränderungen und Zusammenleben gestalten und wer dazu beiträgt, sind hierbei vordergründige Fragen. Tatsächlich gehen die Gestaltung von Wandel und Zusammenleben an die Wurzel des Selbstverständnisses einer jeden Gesellschaft. Es ist jedoch deutlich, dass gesellschaftliche Kohäsion nicht nur von einigen Akteuren geschaffen und aufrechterhalten werden kann beziehungsweise dass es für das Allgemeinwohl nicht ausreicht, wenn diese nur innerhalb von parallel existierenden Gruppen besteht.</p>
                            <p>In diesem Kontext wurde mehrfach beobachtet, in welcher Form sich das Verantwortungsbewusstsein in Deutschland in den letzten Jahren weiterentwickelt hat. Beizutragen wird nicht mehr als eine Last, sondern vielmehr als Gelegenheit, das Gemeinwohl zu bereichern, betrachtet. Durch solch eine Perspektive führt das gemeinsame Schaffen und Handeln in Projekten und die Bewältigung von Herausforderungen zum Aufbau neuer Beziehungen und gegenseitiger Wertschätzung, ebenso wie zur Fortentwicklung der Gesellschaft in Richtung Kohäsion. Daher stellt sich weniger die Frage, ob jeder partizipieren kann, sondern vielmehr, wie jeder entlang eines gemeinsamen Weges zu einer gemeinsamen Vorstellung von gesellschaftlichem Zusammenhalt beitragen kann. Die dynamische Vielfalt, die der hiesigen Gesellschaft innewohnt – sei es die Vielfalt an autochthonen Deutschen oder die Vielfalt der deutschen Bevölkerung, die Vielfalt der derzeitig in Deutschland kurz- oder langfristig ansässigen Personen etc. – ist fester Bestandteil dieses Weges.</p>
                        </div>
                        <div className="col-lg-5">
                            <StaticQuery query={graphql`
                                query {
                                    file(relativePath: { eq: "ueber-uns.jpg" }) {
                                        childImageSharp {
                                            fluid(maxWidth: 1000) {
                                                ...GatsbyImageSharpFluid_noBase64
                                            }
                                        }
                                    }
                                }
                            `} render={data=> (
                                <Img fluid={data.file.childImageSharp.fluid} />
                            )} />
                        </div>
                    </div>
                </div>
            </Section>
        </InnerPage>
         
    )
}

export default Ueber